import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from 'angular-toastify';
import { catchError, of, Subscription, switchMap, tap } from 'rxjs';
import { VALID_AGES, VALID_CEFRS } from 'src/app/shared/constants';
import { ImageFiles, IUploadImage } from 'src/app/shared/models/image-upload.model';
import { ILessonContent, IUpdateLessonContent } from 'src/app/shared/models/lesson-content.model';
import { ICreateLesson, ILesson, ILessonType } from 'src/app/shared/models/lesson.model';
import { ImageUploaderService } from 'src/app/shared/services/image-uploader.service';
import { LessonService } from 'src/app/shared/services/lesson.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-copy-lesson',
	templateUrl: './copy-lesson.component.html',
	styleUrls: ['./copy-lesson.component.scss']
})
export class CopyLessonDialog implements OnInit, OnDestroy {

	private copySubscription!: Subscription;
	private contentSubscription!: Subscription;

	readonly ages = VALID_AGES;
	readonly cefrs = VALID_CEFRS;
	readonly LessonType = ILessonType;
    readonly types = Object.values(ILessonType);
	readonly owners = environment.KNOWN_OWNERS;

	isUploading = false;

	public files: ImageFiles[] = [];
	imageExists: boolean = false;
	toVerify: boolean = false;
	imageVerify!: string;

	nameControl = new FormControl(this.data.displayName, Validators.required);
	cefrControl = new FormControl(this.data.cefr, Validators.required);
	ageControl = new FormControl(this.data.age, Validators.required);
	captionControl = new FormControl(this.data.caption, Validators.required);
	vocabularyControl = new FormControl(this.data.vocabulary, Validators.required);
	grammarControl = new FormControl(this.data.grammar, Validators.required);
	aimControl = new FormControl(this.data.aim, Validators.required);
	ownerControl = new FormControl(this.data.owner, Validators.required);
	typeControl = new FormControl(null, Validators.required);

	lessonContent!: ILessonContent;

	constructor(
		public dialogRef: MatDialogRef<CopyLessonDialog>,
		@Inject(MAT_DIALOG_DATA) public data: ILesson,
		private toastService: ToastService,
		private lessonService: LessonService,
		private uploadService: ImageUploaderService,
	) {
		if (this.data.image) {
			this.imageExists = true;
		}

		this.contentSubscription = this.lessonService.getLessonContent(this.data.uid).subscribe(data => {
			if (!data) {
				this.toastService.error('Failed to copy lesson. Lesson content could not be retrieved.');
				return;
			}

			this.lessonContent = data.content;
		});
	}
	ngOnDestroy(): void {
		if (this.copySubscription) {
			this.copySubscription.unsubscribe();
		}

		if (this.contentSubscription) {
			this.contentSubscription.unsubscribe();
		}
	}

	ngOnInit(): void {

	}

	changeImage(newValue: string) {
		this.data.image = newValue;
		this.toVerify = false;
		this.files = [];
		this.imageExists = false;
	}

	verifyImage() {
		this.imageVerify = this.data.image;
		this.toVerify = true;
	}

	setFile(file: ImageFiles | null) {
		if (file === null) {
			this.data.image = '';
			this.files = [];
			return;
		}
		this.data.image = file.name;
		this.files.push(file);
	}

	closeDialog() {
		this.dialogRef.close(false);
	}

	copyLesson() {
		let canUpload = true;
		this.isUploading = true;

		const lessonCopy: ICreateLesson = {
			displayName: this.nameControl.value,
			age: this.data.age,
			cefr: this.data.cefr,
			aim: this.aimControl.value,
			caption: this.captionControl.value,
			grammar: this.grammarControl.value,
			owner: this.data.owner,
			releasePhase: this.data.releasePhase,
			vocabulary: this.vocabularyControl.value,
			lessonType: this.data.lessonType ?? ILessonType.DEFAULT,
			image: this.data.image,
			version: this.data.version,
		}

		if (lessonCopy.displayName === '') {
			this.nameControl.markAsTouched();
			canUpload = false;
		}
		if (lessonCopy.cefr as any === '') {
			this.cefrControl.markAsTouched();
			canUpload = false;
		}
		if (lessonCopy.age as any === '') {
			this.ageControl.markAsTouched();
			canUpload = false;
		}
		if (lessonCopy.caption === '') {
			this.captionControl.markAsTouched();
			canUpload = false;
		}
		if (lessonCopy.vocabulary === '') {
			this.vocabularyControl.markAsTouched();
			canUpload = false;
		}
		if (lessonCopy.grammar === '') {
			this.grammarControl.markAsTouched();
			canUpload = false;
		}
		if (lessonCopy.aim === '') {
			this.aimControl.markAsTouched();
			canUpload = false;
		}
		if (lessonCopy.owner === '') {
			this.ownerControl.markAsTouched();
			canUpload = false;
		}

		if (lessonCopy.lessonType as any === '') {
			this.typeControl.markAsTouched();
			canUpload = false;
		}

		if (!this.imageExists && this.files.length === 0) {
			this.toastService.error('No image has been selected.');
			this.isUploading = false;
			return;
		}

		if (!canUpload) {
			this.toastService.error('Failed to copy lesson.');
			this.isUploading = false;
			return;
		}

		if (this.files.length > 0) {
			const uploadFileIds = this.files.map(file => {
				return {
					id: file.name,
					extension: 'jpg',
				} as IUploadImage;
			})

			const fileBlobs = this.files.map(fileInfo => {
				const imageBlob = new Blob([fileInfo.file], {type: `image/jpeg`})
				return imageBlob;
			});

			this.uploadService.uploadImages(fileBlobs, uploadFileIds).subscribe(res => {
				if (!res) {
					this.toastService.error('Failed to upload image');
					this.isUploading = false;
					return;
				} else {
					this.toastService.success('Image was uploaded successfully');
					this.uploadLesson(lessonCopy);
				}
			});
		} else {
			this.uploadLesson(lessonCopy);
		}
	}

	uploadLesson(lessonCopy: ICreateLesson) {
		const updateContent: IUpdateLessonContent = Object.assign({}, this.lessonContent);

		delete updateContent.version;
		delete updateContent.uploadId;
		delete updateContent.lessonId;

		this.copySubscription = this.lessonService.createLessonInfo(lessonCopy).pipe(
			switchMap(res => this.lessonService.createLessonContent(res.uid, updateContent)),
			catchError(error => {
				this.toastService.error(`Failed to copy lesson.`)
				console.log('COPY LESSON', error);
				this.isUploading = false;
				return of(null);
			}),
		).subscribe(res => {
			if (!res) {
				return;
			}
			lessonCopy.uid = res.uid;
			(lessonCopy as ILesson).isUploading = true;

			this.dialogRef.close(lessonCopy as ILesson);
		})
	}
}
