import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { FormArray, FormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ToastService } from "angular-toastify";
import { Subscription } from "rxjs";
import { VALID_AGES } from "src/app/shared/constants";
import { IAccount } from "src/app/shared/models/account.model";
import {
	ALG_TYPES,
	Dungeon,
	DungeonData,
	DungeonLesson,
} from "src/app/shared/models/dungeon.model";
import {
	IUploadImage,
	ImageFiles,
} from "src/app/shared/models/image-upload.model";
import { ILesson } from "src/app/shared/models/lesson.model";
import { DungeonService } from "src/app/shared/services/dungeon.service";
import { ImageUploaderService } from "src/app/shared/services/image-uploader.service";
import { LessonService } from "src/app/shared/services/lesson.service";
import { AccountStore } from "src/app/shared/services/stores/account.store.service";
import { Owner } from "src/environments/environment.model";

@Component({
	selector: "app-add-dungeon",
	templateUrl: "./add-dungeon.component.html",
	styleUrls: ["./add-dungeon.component.scss"],
})
export class AddDungeonDialog implements OnInit, OnDestroy {
	private addSubscription!: Subscription;
	private updateSubscription!: Subscription;
	private subscriptions!: Subscription;

	loading: boolean = true; // Initially loading is true
	selectingLessons: boolean = false;

	algTypes = ALG_TYPES;
	ageValues = VALID_AGES;

	owners: Owner[] = [];
	lessons!: DungeonLesson[];
	selectedLessons: DungeonLesson[] = [];

	nameControl = new FormControl(null, Validators.required);
	ownerControl = new FormControl(null, Validators.required);
	lessonsControl = new FormControl([], Validators.required);
	ageControl = new FormControl(null, Validators.required);
	recControl = new FormControl(null, Validators.required);

	isUploading = false;

	constructor(
		public dialogRef: MatDialogRef<AddDungeonDialog>,
		@Inject(MAT_DIALOG_DATA) public data: Dungeon,
		private accountStore: AccountStore,
		private lessonService: LessonService,
		private toastService: ToastService,
		private uploadService: ImageUploaderService,
		private dungeonService: DungeonService
	) {
		this.subscriptions = new Subscription();
	}

	ngOnInit() {
		this.loadData();

		this.nameControl.setValue(this.data.name);
		this.ownerControl.setValue(this.data.owner);
		this.lessonsControl.setValue(this.data.lessons);
		this.ageControl.setValue(this.data.age);
		this.recControl.setValue(this.data.recommendation.type);
		this.selectedLessons = this.data.lessons.slice();

		if (this.data.imageId != "") {
			this.imageExists = true;
			this.toVerify = true;
		}
	}

	ngOnDestroy(): void {
		if (this.subscriptions) {
			this.subscriptions.unsubscribe();
		}

		if (this.addSubscription) {
			this.addSubscription.unsubscribe();
		}

		if (this.updateSubscription) {
			this.updateSubscription.unsubscribe();
		}
	}

	private loadData() {
		this.loading = true;

		const accountSub = this.accountStore.listAccounts().subscribe((res) => {
			const systemOwner: Owner = {
				displayName: "SYSTEM",
				id: "system/system",
			};
			const devOwner: Owner = { displayName: "DEV", id: "dev/dev" };
			const arcadeOwner: Owner = {
				displayName: "ARCADE",
				id: "arcade/arcade",
			};
			const placementOwner: Owner = {
				displayName: "PLACEMENT",
				id: "placement/placement",
			};
			const accountOwners: Owner[] = (res as IAccount[])
				.sort((a, b) => a.displayName.localeCompare(b.displayName))
				.map((acc) => ({
					id: `accounts/${acc.uid}`,
					displayName: acc.displayName,
				}));

			this.owners = [
				systemOwner,
				devOwner,
				arcadeOwner,
				placementOwner,
				...accountOwners,
			];
			this.filteredOwners = [...this.owners];
		});

		const lessonSub = this.lessonService.getLessons().subscribe({
			next: (res) => {
				this.lessons = res
					.sort((a, b) => a.displayName.localeCompare(b.displayName))
					.map((lesson) => {
						return {
							lessonId: lesson.uid,
							age: lesson.age,
							displayName: lesson.displayName,
							version: lesson.version,
						} as DungeonLesson;
					});
				this.filterLessons();
			},
			error: (err) => {
				console.error(err);
				this.toastService.error("Failed to load lessons");
				this.lessons = [];
			},
			complete: () => {
				this.loading = false; // Set loading to false once all data is loaded
			},
		});

		this.subscriptions.add(accountSub);
		this.subscriptions.add(lessonSub);
	}

	//#region LESSONS

	lessonSearchTerm: string = "";

	openLessonSelect() {}

	filterLessons() {
		this.filteredContent = this.lessons.filter(
			(lesson) =>
				!this.selectedLessons.some(
					(selected) => selected.lessonId === lesson.lessonId
				) &&
				(!this.lessonSearchTerm ||
					lesson.displayName
						.toLowerCase()
						.includes(this.lessonSearchTerm.toLowerCase()))
		);
	}

	addLesson(lesson: DungeonLesson) {
		this.selectedLessons.push(lesson);
		this.filterLessons();
	}

	removeLesson(lesson: DungeonLesson) {
		this.selectedLessons = this.selectedLessons.filter(
			(l) => l.lessonId !== lesson.lessonId
		);
		this.filterLessons();
	}

	//#endregion

	//#region SEARCH

	searchTerm: string = "";
	filteredOwners: any[] = [];
	filteredContent: any[] = [];

	filterOptions<T extends { displayName: string }>(
		sourceList: T[],
		filteredList: T[],
		type: string
	) {
		if (!this.searchTerm) {
			filteredList.length = 0;
			filteredList.push(...sourceList);
			return;
		}

		const lowerSearch = this.searchTerm.toLowerCase();
		filteredList.length = 0;
		filteredList.push(
			...sourceList.filter((item) =>
				item.displayName.toLowerCase().includes(lowerSearch)
			)
		);
	}

	clearSearch<T>(sourceList: T[], filteredList: T[]) {
		this.searchTerm = "";
		filteredList.length = 0;
		filteredList.push(...sourceList);
	}

	//#endregion

	//#region IMAGE UPLOAD

	public files: ImageFiles[] = [];
	imageExists: boolean = false;
	toVerify: boolean = false;
	imageVerify!: string;

	changeImage(newValue: string) {
		this.data.imageId = newValue;
		this.toVerify = false;
		this.files = [];
		this.imageExists = false;
	}

	verifyImage() {
		this.toVerify = true;
	}

	setFile(file: ImageFiles | null) {
		if (file === null) {
			this.data.imageId = "";
			this.files = [];
			return;
		}
		this.data.imageId = file.name;
		this.files.push(file);
	}

	//#endregion

	//#region Dialog Actions
	onCloseClick() {
		this.dialogRef.close();
	}

	onSaveClick() {
		let canUpload = true;
		this.isUploading = true;

		if (this.data.name === "") {
			this.nameControl.markAsTouched();
			canUpload = false;
		}

		if (this.data.owner === "") {
			this.ownerControl.markAsTouched();
			canUpload = false;
		}

		if (this.selectedLessons.length === 0) {
			this.toastService.error("No lesson has been selected.");
			this.lessonsControl.markAsTouched();
			canUpload = false;
		}

		if (this.data.age === "") {
			this.ageControl.markAsTouched();
			canUpload = false;
		}

		if (!this.data.recommendation) {
			this.recControl.markAsTouched();
			canUpload = false;
		}

		if (!this.imageExists && this.files.length === 0) {
			this.toastService.error("No image has been selected.");
			this.isUploading = false;
			return;
		}

		if (!canUpload) {
			this.toastService.error("Failed to save new Dungeon.");
			this.isUploading = false;
			return;
		}

		this.data.lessons = this.selectedLessons;

		if (this.files.length > 0) {
			const uploadFileIds = this.files.map((file) => {
				return {
					id: file.name,
					extension: "jpg",
				} as IUploadImage;
			});

			const fileBlobs = this.files.map((fileInfo) => {
				const imageBlob = new Blob([fileInfo.file], {
					type: `image/jpeg`,
				});
				return imageBlob;
			});

			this.uploadService
				.uploadImages(fileBlobs, uploadFileIds)
				.subscribe((res) => {
					if (!res) {
						this.toastService.error("Failed to upload image");
						this.isUploading = false;
						return;
					} else {
						this.toastService.success(
							"Image was uploaded successfully"
						);
						if (!this.data.uid || this.data.uid === "") {
							this.createDungeon();
						} else {
							this.updateDungeon();
						}
					}
				});
		} else {
			if (!this.data.uid || this.data.uid === "") {
				this.createDungeon();
			} else {
				this.updateDungeon();
			}
		}
	}

	createDungeon() {
		this.addSubscription = this.dungeonService
			.createDungeon(this.data)
			.pipe()
			.subscribe({
				next: (res) => {
					if (!res) {
						this.toastService.error("Failed to create dungeon.");
						return;
					}
					this.data.uid = res.uid;
					this.dialogRef.close(this.data);
				},
				error: (error) => {
					console.log(error);
					this.toastService.error("Failed to create dungeon.");
					this.isUploading = false;
					return;
				},
			});
	}

	updateDungeon() {
		this.updateSubscription = this.dungeonService
			.updateDungeon(this.data.uid, this.data)
			.pipe()
			.subscribe({
				next: () => {
					this.dialogRef.close(this.data);
				},
				error: (error) => {
					console.log(error);
					this.toastService.error("Failed to update dungeon.");
					this.isUploading = false;
					return;
				},
			});
	}
	//#endregion
}
