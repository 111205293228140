import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { COLLECTIONS } from "../constants";
import { Observable } from "rxjs";
import { IActivity } from "../models/activities.model";

@Injectable({
	providedIn: 'root',
})
export class FirestoreService {

	constructor(
		public readonly db : AngularFirestore,
	) {}

	getLessonActivitiesByVersion(lessonId: string, version: number) : Observable<any>{
		return this.db.collection(COLLECTIONS.Activities, ref =>
			ref.where('lessonId', '==', lessonId)
			.where('version', '==', version)
		).valueChanges();
	}
}
