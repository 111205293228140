<div class="component-container">

  <div class="actions-container">

    <div class="select-all-container">
      <mat-label>select all</mat-label>
      <mat-checkbox [checked]="isAllSelected" (change)="selectAll($event)"></mat-checkbox>
    </div>

    <div class="action-buttons-container">

      <div *ngIf="isDraft" class="draft-actions">

        <button class="action-button" mat-icon-button color="primary" (click)="deleteDungeon()">
          <mat-icon>delete_outline</mat-icon>
        </button>

        <button mat-raised-button color="primary" (click)="pushToLive()">Push to Live</button>

        <button mat-mini-fab color="primary" (click)="openAddDialog()">
          <mat-icon>add</mat-icon>
        </button>
      </div>

      <div *ngIf="!isDraft" class="live-actions">
        <button *ngIf="!isDraft" mat-raised-button color="warn" (click)="publishDungeons(false)">Un-publish</button>
        <button *ngIf="!isDraft" mat-raised-button color="primary" (click)="publishDungeons(true)">Publish</button>
      </div>

    </div>

  </div>

  <div class="lesson-table">
    <mat-table *ngIf="owners" [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>NAME</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div class="name-cell" [style.background-color]="row.isContentValid ? '#36B37E' : '#DE350B'">
            {{row.name}}
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>ACTIONS</mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index">
          <div class="buttons-action">

            <mat-checkbox class="checkbox" [checked]="dungeonsSelected[row.uid]"
              (change)="select($event, row.uid)"></mat-checkbox>

            <button *ngIf="isDraft" class="edit-button" mat-icon-button color="primary"
              (click)="editDungeon(row.uid, row)">
              <mat-icon>edit</mat-icon>
            </button>

            <button mat-icon-button [color]="(row.invalidContent && row.invalidContent.length) > 0 ? 'warn' : 'primary'"
              (click)="openContentValidation(row)"
              [matTooltip]="(row.invalidContent && row.invalidContent.length) > 0 ? 'There are problems with the dungeon content.' : 'Dungeon content is valid.'"
              [matTooltipPosition]="'above'">
              <mat-icon>announcement</mat-icon>
            </button>

            <button *ngIf="noLive.includes(row.uid)" mat-icon-button color="warn" matTooltip="Dungeon is not live."
              [matTooltipPosition]="'above'">
              <mat-icon>close</mat-icon>
            </button>

            <button *ngIf="!noLive.includes(row.uid) && !draftAhead[row.uid].isAhead" mat-icon-button color="primary"
              matTooltip="Draft version is the same." [matTooltipPosition]="'above'">
              <mat-icon>done</mat-icon>
            </button>

            <button *ngIf="!noLive.includes(row.uid) && draftAhead[row.uid].isAhead" mat-icon-button color="warn"
              matTooltip="Draft version is ahead." [matTooltipPosition]="'above'">
              <mat-icon>priority_high</mat-icon>
            </button>

            <button class="play-button" mat-icon-button color="primary" (click)="testDungeon(row.uid)">
              <mat-icon>play_circle_filled</mat-icon>
            </button>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef='status'>
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div *ngIf="!isDraft">
            <h3>Status</h3>
            {{row.releasePhase}}
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="image">
        <mat-header-cell *matHeaderCellDef> IMAGE </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <img [src]="createImage(row.imageId)"
            style="max-width: 170px; margin-right: 5px; align-self:flex-start; overflow-y: none;">
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="lessons">
        <mat-header-cell *matHeaderCellDef> LESSONS </mat-header-cell>
        <mat-cell *matCellDef="let row" class="text-cell">
          <div>
            <h3>Lessons</h3>
            <div class="scroll-box">
              <div *ngFor="let lesson of row.lessons">
                <strong>{{ lesson.displayName }}</strong> &nbsp; &nbsp; Version: {{ lesson.version }}
              </div>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="age">
        <mat-header-cell *matHeaderCellDef mat-sort-header> AGE </mat-header-cell>
        <mat-cell *matCellDef="let row" class="text-cell">
          <div>
            <h3>Age</h3>
            {{row.age}}
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="recommendation">
        <mat-header-cell *matHeaderCellDef mat-sort-header> TYPE </mat-header-cell>
        <mat-cell *matCellDef="let row" class="text-cell">
          <div>
            <h3>RecommendationType</h3>
            {{row.recommendation.type}}
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="version">
        <mat-header-cell *matHeaderCellDef mat-sort-header> VERSION </mat-header-cell>
        <mat-cell *matCellDef="let row" class="text-cell">
          <div>
            <h3>Version</h3>
            {{row.version}}
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="owner">
        <mat-header-cell *matHeaderCellDef mat-sort-header> OWNER </mat-header-cell>
        <mat-cell *matCellDef="let row" class="text-cell">
          <div>
            <h3>Owner</h3>
            {{row.owner ? getOwner(row.owner) : 'System'}}
          </div>
        </mat-cell>
      </ng-container>

      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

    </mat-table>

  </div>
  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="defaultPageSize" (page)="paginatorChanged($event)"
    aria-label="Select page of lessons" [showFirstLastButtons]="true">
  </mat-paginator>

</div>