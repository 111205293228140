<div mat-dialog-title color="primary">
    CREATE PROGRAM
</div>

<div mat-dialog-content class='dialog-content'>

    <mat-stepper linear>
        <mat-step>
            <ng-template matStepLabel>General Information</ng-template>
            <div class="step-container">

                <div class="step-content">
                    <form class="information-form" [formGroup]="informationGroup">

                        <mat-form-field appearance="fill">
                            <mat-label>Name</mat-label>
                            <input matInput formControlName="name">
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Description</mat-label>
                            <textarea matInput formControlName="description"></textarea>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Cefr</mat-label>
                            <mat-select formControlName="targetCefr">
                                <mat-option *ngFor="let cefr of availableCefr" [value]="cefr">
                                    {{cefr}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Age</mat-label>
                            <mat-select formControlName="targetAgeRange">
                                <mat-option *ngFor="let age of availableAges" [value]="age">
                                    {{age}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </form>
                </div>
                <div class="step-btn-container">
                    <button mat-raised-button color="primary" [disabled]="!informationGroup.valid"
                        matStepperNext>Next</button>
                </div>
            </div>
        </mat-step>

        <mat-step>
            <ng-template matStepLabel>Image</ng-template>
            <div class="step-container">
                <div class="step-content">
                    <mat-form-field appearance="fill">
                        <mat-label>Image</mat-label>
                        <input matInput [formControl]="imageControl" (keyup)="resetImage()">
                    </mat-form-field>
                    <button mat-raised-button color="primary" (click)="verifyImage()">Verify Image</button>
                    <div class="image-container">
                        <app-image-uploader *ngIf="toVerify" [imageId]="imageVerify" (uploadFiles)="setFile($event)"
                            (exists)="setImage($event)"></app-image-uploader>
                    </div>
                </div>
                <div class="step-btn-container">
                    <button mat-raised-button color="primary" matStepperPrevious>Previous</button>
                    <button mat-raised-button color="primary" [disabled]="!imageControl.valid"
                        matStepperNext>Next</button>
                </div>
            </div>
        </mat-step>

        <mat-step>
            <ng-template matStepLabel>Owner & tags</ng-template>
            <div class="step-container">
                <div class="step-content">
                    <div class="owner-tag-container">
                        <mat-label>Owner</mat-label>
                        <div class="owner-container">
                            <mat-checkbox class="owner-check" [(ngModel)]="isPrivateProgram"
                                (change)="privateCheckChanged($event.checked)">
                                This is a Private Program
                            </mat-checkbox>
                            <mat-form-field *ngIf="isPrivateProgram" class="owner-selector" appearance="fill">
                                <mat-label>Owner</mat-label>
                                <mat-select [formControl]="ownerControl">
                                    <mat-option *ngFor="let owner of availableOwners" [value]="owner.uid">
                                        {{owner.displayName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <mat-divider></mat-divider>

                        <mat-label>Tags</mat-label>

                        <div class="tag-select-container">

                            <div class="tag-selector-container">
                                <div class="list-item">
                                    <div class="tag-value">
                                        <mat-label>pt</mat-label>
                                    </div>

                                    <div class="tag-value">
                                        <mat-label>en</mat-label>
                                    </div>

                                    <div class="tag-value">
                                        <mat-label>es</mat-label>
                                    </div>

                                    <div class="tag-value">
                                    </div>
                                </div>

                                <div class="list-item">
                                    <form class="tag-form" [formGroup]="tagGroup">
                                        <div class="tag-value">
                                            <mat-form-field class="tag-input">
                                                <mat-label>pt value</mat-label>
                                                <input formControlName="ptControl" matInput>
                                            </mat-form-field>
                                        </div>

                                        <div class="tag-value">
                                            <mat-form-field class="tag-input">
                                                <mat-label>en value</mat-label>
                                                <input formControlName="enControl" matInput>
                                            </mat-form-field>
                                        </div>

                                        <div class="tag-value">
                                            <mat-form-field class="tag-input">
                                                <mat-label>es value</mat-label>
                                                <input formControlName="esControl" matInput>
                                            </mat-form-field>
                                        </div>

                                        <div class="tag-value">
                                            <button [disabled]="!tagGroup.valid" mat-mini-fab color="primary"
                                                (click)="createTags()">
                                                <mat-icon>add</mat-icon>
                                            </button>
                                        </div>
                                    </form>
                                </div>

                                <div class="list tag-picker">
                                    <div class="loading" *ngIf="tagsLoading">
                                        <mat-spinner></mat-spinner>
                                    </div>
                                    <div class="list-item" *ngFor="let tag of tagIds;">

                                        <div class="tag-value">
                                            <div><b>{{getTagValue(tag, 'pt')}}</b></div>
                                        </div>

                                        <div class="tag-value">
                                            <div><b>{{getTagValue(tag, 'en')}}</b></div>
                                        </div>

                                        <div class="tag-value">
                                            <div><b>{{getTagValue(tag, 'es')}}</b></div>
                                        </div>

                                        <div class="tag-value">
                                            <button mat-mini-fab color="primary" (click)="addTag(tag)">
                                                <mat-icon>arrow_forward</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="tag-list-container">
                                <div class="list">
                                    <div class="list-item"
                                        *ngFor="let tag of selectedTags; let tagIndex = index">
                                        <div class="item-text">{{getTagValue(tag, 'en')}}</div>
                                        <div>
                                            <button mat-icon-button color="primary" (click)="removeTag(tagIndex)">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="step-btn-container">
                    <button mat-raised-button color="primary" matStepperPrevious>Back</button>
                    <button mat-raised-button color="primary" matStepperNext
                        [disabled]="!(isPrivateProgram === ownerControl.valid) || selectedTags.length === 0">Next</button>
                </div>
            </div>
        </mat-step>

        <mat-step>
            <ng-template matStepLabel>Content</ng-template>
            <div class="step-container">

                <div class="step-content">
                    <div class="content-select-container">
                        <div class="lesson-table-container">

                            <mat-form-field appearance="standard">
                                <mat-label>Filter</mat-label>
                                <input matInput (keyup)="applyFilter($event)" placeholder="Ex. program1" #input>
                            </mat-form-field>

                            <table mat-table [dataSource]="lessonDataSource">

                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef> Name </th>
                                    <td mat-cell *matCellDef="let lesson"> {{lesson.displayName}} </td>
                                </ng-container>

                                <ng-container matColumnDef="cefr">
                                    <th mat-header-cell *matHeaderCellDef> Cefr </th>
                                    <td mat-cell *matCellDef="let lesson"> {{lesson.cefr}} </td>
                                </ng-container>

                                <ng-container matColumnDef="age">
                                    <th mat-header-cell *matHeaderCellDef> Age </th>
                                    <td mat-cell *matCellDef="let lesson"> {{lesson.age}} </td>
                                </ng-container>

                                <ng-container matColumnDef="caption">
                                    <th mat-header-cell *matHeaderCellDef> Caption </th>
                                    <td mat-cell *matCellDef="let lesson"> {{lesson.caption}} </td>
                                </ng-container>

                                <ng-container matColumnDef="pick">
                                    <th mat-header-cell *matHeaderCellDef> Pick </th>
                                    <td mat-cell *matCellDef="let lesson">
                                        <button mat-mini-fab color="primary" (click)="addLesson(lesson)">
                                            <mat-icon>add</mat-icon>
                                        </button>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="lessonColumns"></tr>
                                <tr mat-row *matRowDef="let myRowData; columns: lessonColumns"></tr>
                            </table>
                            <mat-paginator [pageSizeOptions]="[4 , 8]" showFirstLastButtons #lessonpaginator>
                            </mat-paginator>
                        </div>

                        <div class="lesson-list-container">
                            <div cdkDropList class="list" (cdkDropListDropped)="drop($event)">
                                <div class="list-item" *ngFor="let lessonId of selectedLessons;let lessonIndex = index"
                                    cdkDrag>
                                    <div class="item-index">{{lessonIndex + 1}}</div>
                                    <div class="item-text">{{getLessonName(lessonId)}}</div>
                                    <div class="item-remove">
                                        <button mat-icon-button color="primary" (click)="removeLesson(lessonIndex)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="step-btn-container">
                    <button *ngIf="!isRegistering" mat-raised-button color="primary" matStepperPrevious>Back</button>
                    <button *ngIf="!isRegistering" mat-raised-button color="accent" [disabled]="
                    !informationGroup.valid || 
                    !(isPrivateProgram === ownerControl.valid) || 
                    selectedTags.length === 0 || 
                    selectedLessons.length === 0 ||
                    isRegistering" 
                    (click)="registerProgram()">Save</button>
                    <mat-spinner *ngIf="isRegistering" [diameter]="25"></mat-spinner>
                </div>
            </div>
        </mat-step>

    </mat-stepper>

</div>