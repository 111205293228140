<div class="component-container">

    <div class="actions-container">

        <div class="select-all-container">
            <!-- <label for="select-all">select all</label> -->
            <mat-label>select all</mat-label>
            <mat-checkbox (change)="selectAll($event)"></mat-checkbox>
        </div>

        <form class="actions-filter">
            <mat-form-field class="keyword-field" appearance="fill">
                <mat-label>Palavra chave</mat-label>
                <input matInput [ngModel]="globalfilter" (ngModelChange)="applyFilter($event)"
                    [ngModelOptions]="{standalone: true}">
            </mat-form-field>

            <mat-form-field class="filter-field" appearance="fill">
                <mat-label>CEFR</mat-label>
                <mat-select [formControl]="cefrControl">
                    <mat-option>nenhum</mat-option>
                    <mat-option *ngFor="let cefr of cefrs" [value]="cefr">
                        {{cefr}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="filter-field" appearance="fill">
                <mat-label>Idade</mat-label>
                <mat-select [formControl]="ageControl">
                    <mat-option>nenhum</mat-option>
                    <mat-option *ngFor="let age of ages" [value]="age">
                        {{age}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="filter-field" appearance="fill">
                <mat-label>Owner</mat-label>
                <mat-select [formControl]="ownerControl">
                    <mat-option>nenhum</mat-option>
                    <mat-option *ngFor="let owner of owners" [value]="owner">
                        {{owner.displayName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="filter-field" appearance="fill">
                <mat-label>Version Dif</mat-label>
                <mat-select [formControl]="versionDifControl">
                    <mat-option [value]="null">nenhum</mat-option>
                    <mat-option *ngFor="let version of [{value : 'diff', text : 'Different'}, {value : 'same', text: 'Same'}, {value : 'notLive', text : 'Not Live'}]" [value]="version.value"> {{version.text}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="isDraft" class="filter-field" appearance="fill">
                <mat-label>End Status</mat-label>
                <mat-select [formControl]="endStatusControl">
                    <mat-option [value]="null">nenhum</mat-option>
                    <mat-option *ngFor="let status of [{value : true, text : 'Done'}, {value : false, text: 'Error'}]" [value]="status.value"> {{status.text}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="filter-field" appearance="fill">
                <mat-label>Type</mat-label>
                <mat-select [formControl]="typeControl">
                    <mat-option [value]="null">nenhum</mat-option>
                    <mat-option *ngFor="let type of types" [value]="type"> {{type}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="!isDraft" class="filter-field" appearance="fill">
                <mat-label>Status</mat-label>
                <mat-select [formControl]="statusControl">
                    <mat-option [value]="null">nenhum</mat-option>
                    <mat-option *ngFor="let status of ['Published', 'Not Published']" [value]="status"> {{status}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

        </form>

        <div class="action-buttons-container">

            <div *ngIf="isDraft" class="draft-actions">
                <button mat-mini-fab color="primary" (click)="openAddDialog()">
                    <mat-icon>add</mat-icon>
                </button>

                <button class="action-button" mat-icon-button color="primary" (click)="copySelectedLesson()">
                    <mat-icon>content_copy</mat-icon>
                </button>

                <button class="action-button" mat-icon-button color="primary" (click)="removeSelectedLessons()">
                    <mat-icon>delete_outline</mat-icon>
                </button>

                <button mat-raised-button (click)="regenerateSelectedLessons()">Regenerate</button>
                <button mat-raised-button color="primary" (click)="pushSelectedLessonsToLive()">Push to Live</button>
            </div>

            <div *ngIf="!isDraft" class="live-actions">
                <button *ngIf="!isDraft" mat-raised-button color="warn"
                    (click)="publishLessons(false)">Un-publish</button>
                <button *ngIf="!isDraft" mat-raised-button color="primary"
                    (click)="publishLessons(true)">Publish</button>
            </div>

        </div>

    </div>

    <div class="lesson-table">
        <mat-table *ngIf="owners" [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef> NAME </mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <div class="name-cell" [style.background-color]="row.isContentValid ? '#36B37E' : '#DE350B'">
                        {{row.displayName}}
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef> ACTIONS </mat-header-cell>
                <mat-cell *matCellDef="let row; let i = index">
                    <div class="buttons-action">

                        <mat-checkbox class="checkbox" [checked]="lessonsSelected[row.uid]"
                            (change)="select($event, row.uid)"></mat-checkbox>

                        <!-- <button class="delete-button" mat-icon-button color="primary" (click)="remove(i, row.uid)">
                            <mat-icon>delete</mat-icon>
                        </button> -->

                        <button *ngIf="isDraft" class="edit-button" mat-icon-button color="primary" (click)="editLesson(row.uid)">
                            <mat-icon>edit</mat-icon>
                        </button>

                        <button class="games-button" mat-icon-button color="primary" (click)="openClassroomGames(row.uid)">
                            <mat-icon>videogame_asset</mat-icon>
                        </button>

                        <button class="play-button" mat-icon-button color="primary" (click)="testLesson(row.uid)">
                            <mat-icon>play_circle_filled</mat-icon>
                        </button>
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="image">

                <mat-header-cell *matHeaderCellDef> IMAGE </mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <img [src]="createImage(row.image)"
                        style="max-width: 170px; margin-right: 5px; align-self:flex-start; overflow-y: none;">
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="cefr">
                <mat-header-cell *matHeaderCellDef mat-sort-header> CEFR </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let row" class="text-cell">
                    <div>
                        <h3>CEFR</h3>
                        {{row.cefr}}
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="age">
                <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> AGE </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let row" class="text-cell">
                    <div>
                        <h3>Age</h3>
                        {{row.age}}
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="vocabulary">
                <mat-header-cell mat-header-cell *matHeaderCellDef> VOCABULARY </mat-header-cell>
                <mat-cell *matCellDef="let row" class="text-cell">
                    <div>
                        <h3>Vocabulary</h3>
                        {{row.vocabulary}}
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="grammar">
                <mat-header-cell *matHeaderCellDef> GRAMMAR </mat-header-cell>
                <mat-cell class="grammar" *matCellDef="let row" class="text-cell">
                    <div>
                        <h3>Grammar</h3>
                        {{row.grammar}}
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="aim">
                <mat-header-cell *matHeaderCellDef> AIM </mat-header-cell>
                <mat-cell class="aim" *matCellDef="let row" class="text-cell">
                    <div>
                        <h3>Aim</h3>
                        {{row.aim}}
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="caption">
                <mat-header-cell *matHeaderCellDef> CAPTION </mat-header-cell>
                <mat-cell class="caption" *matCellDef="let row" class="text-cell">
                    <div>
                        <h3>Caption</h3>
                        {{row.caption}}
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="owner">
                <mat-header-cell *matHeaderCellDef mat-sort-header> OWNER </mat-header-cell>
                <mat-cell *matCellDef="let row" class="text-cell">
                    <div>
                        <h3>Owner</h3>
                        {{row.owner ? getOwner(row.owner) : 'System'}}
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="type">
                <mat-header-cell *matHeaderCellDef mat-sort-header> TYPE </mat-header-cell>
                <mat-cell *matCellDef="let row" class="text-cell">
                    <div>
                        <h3>Type</h3>
                        {{row.lessonType ? row.lessonType : LessonType.DEFAULT}}
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef='status'>
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <div *ngIf="!isDraft">
                        <h3>Status</h3>
                        {{row.releasePhase}}
                        
                       
                        <button *ngIf="!draftAhead[row.uid].isAhead" 
                        mat-icon-button color="primary" 
                        matTooltip="Draft version is the same." 
                        [matTooltipPosition]="'above'">
                            <mat-icon>done</mat-icon>
                        </button>

                        <button *ngIf="draftAhead[row.uid].isAhead" 
                        mat-icon-button color="warn" 
                        matTooltip="Draft version is ahead." 
                        [matTooltipPosition]="'above'">
                            <mat-icon>priority_high</mat-icon>
                        </button>
                    </div>

                    <div *ngIf="isDraft"  class="buttons-action">
                        <button *ngIf="row.lessonType === LessonType.CUSTOM" mat-icon-button color="primary" (click)="openCustomConfig(row)">
                            <mat-icon>star</mat-icon>
                        </button>


                        <button mat-icon-button color="primary" (click)="openUploadState(row.uid)">
                            <mat-icon>schedule</mat-icon>
                        </button>

                        <button mat-icon-button 
                            [color]="(row.invalidContent && row.invalidContent.length) > 0 ? 'warn' : 'primary'" 
                            (click)="openContentValidation(row)"
                            [matTooltip]="(row.invalidContent && row.invalidContent.length) > 0 ? 'There are problems with the activity content.' : 'Activity content is valid.'" 
                            [matTooltipPosition]="'above'">
                            <mat-icon>announcement</mat-icon>
                        </button>

                        <button *ngIf="noLive.includes(row.uid)" 
                        mat-icon-button color="warn" 
                        matTooltip="Lesson is not live." 
                        [matTooltipPosition]="'above'">
                            <mat-icon>close</mat-icon>
                        </button>

                        <button *ngIf="!noLive.includes(row.uid) && !draftAhead[row.uid].isAhead" 
                        mat-icon-button color="primary" 
                        matTooltip="Draft version is the same."
                        [matTooltipPosition]="'above'">
                            <mat-icon>done</mat-icon>
                        </button>

                        <button *ngIf="!noLive.includes(row.uid) && draftAhead[row.uid].isAhead" 
                        mat-icon-button color="warn" 
                        matTooltip="Draft version is ahead." 
                        [matTooltipPosition]="'above'">
                            <mat-icon>priority_high</mat-icon>
                        </button>
                        
                    </div>

                </mat-cell>
            </ng-container>

            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

        </mat-table>
    </div>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="defaultPageSize" (page)="paginatorChanged($event)"
        aria-label="Select page of lessons" [showFirstLastButtons]="true">
    </mat-paginator>
</div>