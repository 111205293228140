<div class="content-view">
  <lib-toastify-toast-container position="bottom-right"></lib-toastify-toast-container>
  <div class="header">
    <mat-toolbar class="header" color="primary">
      <button mat-icon-button color="white" (click)="home()">
        <mat-icon>home</mat-icon>
      </button>
      <div class="start-header">
        <h1 class="title">Activity Descriptions</h1>
      </div>
      <div class="end-header">
        <button mat-mini-fab color="white" (click)="logout()">
          <mat-icon>logout</mat-icon>
        </button>
      </div>
    </mat-toolbar>
  </div>

  <div class="component-container">
    <div class="actions-container">
      <form class="actions-filter">
        <mat-form-field class="keyword-field" appearance="fill">
          <mat-label>Palavra chave</mat-label>
          <input matInput [ngModel]="globalfilter" (ngModelChange)="applyFilter($event)"
            [ngModelOptions]="{ standalone: true }" />
        </mat-form-field>

        <mat-form-field class="filter-field" appearance="fill">
          <mat-label>Habilidades</mat-label>
          <mat-select [formControl]="skillControl">
            <mat-option>nenhum</mat-option>
            <mat-option *ngFor="let skill of skills" [value]="skill">
              {{ skill }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="filter-field" appearance="fill">
          <mat-label>Idade</mat-label>
          <mat-select [formControl]="ageControl">
            <mat-option>nenhum</mat-option>
            <mat-option *ngFor="let age of ages" [value]="age">
              {{ age }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="filter-field" appearance="fill">
          <mat-label>Cognition</mat-label>
          <mat-select [formControl]="cognitionControl">
            <mat-option>nenhum</mat-option>
            <mat-option *ngFor="let cognition of cognitions" [value]="cognition">
              {{ cognition }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>

      <div class="action-buttons-container">
        <div class="top-actions">
          <button mat-mini-fab color="primary" (click)="createDescription()">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div class="loading" *ngIf="loading">
      <mat-spinner></mat-spinner>
    </div>

    <div class="descriptions-table" *ngIf="!loading">
      <mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef> NAME </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <div class="name-cell">
              {{ row.name }}
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef> ACTIONS </mat-header-cell>
          <mat-cell *matCellDef="let row; let i = index">
            <div class="buttons-action">
              <button class="edit-button" mat-icon-button color="primary" (click)="editDescription(i, row)">
                <mat-icon>edit</mat-icon>
              </button>
            </div>
            <div class="buttons-action">
              <button class="copy-button" mat-icon-button color="primary" (click)="copyDescription(i, row)">
                <mat-icon>content_copy</mat-icon>
              </button>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="skill">
          <mat-header-cell *matHeaderCellDef mat-sort-header> SKILL </mat-header-cell>
          <mat-cell mat-cell *matCellDef="let row" class="text-cell">
            <div>
              <h3>Habilidade</h3>
              <div class="skill-container">
                <button class="skill-icon" [ngClass]="{ active: row.skill === 'listening' }" translate>
                  <mat-icon>hearing</mat-icon>
                  Listening
                </button>

                <button class="skill-icon" [ngClass]="{ active: row.skill === 'reading' }" translate>
                  <mat-icon>chrome_reader_mode</mat-icon>
                  Reading
                </button>

                <button class="skill-icon" [ngClass]="{ active: row.skill === 'writing' }" translate>
                  <mat-icon>create</mat-icon>
                  Writing
                </button>

                <button class="skill-icon" [ngClass]="{ active: row.skill === 'speaking' }" translate>
                  <mat-icon>mic</mat-icon>
                  Speaking
                </button>
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="ages">
          <mat-header-cell mat-header-cell *matHeaderCellDef> Ages </mat-header-cell>
          <mat-cell *matCellDef="let row" class="text-cell">
            <div>
              <h3>Idades</h3>
              <div class="tag-container">
                <span *ngFor="let age of row.ages">
                  {{age}}
                  <span *ngIf="row.ages.length > 1 && age !== row.ages[row.ages.length - 1]">,</span>
                </span>
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="cognition">
          <mat-header-cell mat-header-cell *matHeaderCellDef> Cognition </mat-header-cell>
          <mat-cell *matCellDef="let row" class="text-cell">
            <div>
              <h3>Cognition</h3>
              <div class="tag-container">
                <span>{{ row.cognition }}</span>
              </div>
            </div>
          </mat-cell>
        </ng-container>


        <ng-container matColumnDef="description">
          <mat-header-cell mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
          <mat-cell *matCellDef="let row" class="text-cell">
            <div>
              <h3>Descrição</h3>
              <div class="tag-container">
                <span>{{ row.description }}</span>
              </div>
            </div>
          </mat-cell>
        </ng-container>



        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="defaultPageSize" (page)="paginatorChanged($event)"
      aria-label="Select page of activityDescriptions" [showFirstLastButtons]="true">
    </mat-paginator>
  </div>
</div>