<div mat-dialog-title color="primary">
	COPY LESSON
</div>

<lib-toastify-toast-container
	position="bottom-right"
></lib-toastify-toast-container>

<div mat-dialog-content class='dialog-content' style="align-items: center;">
    <ng-container class="lesson-name-content">
		<mat-form-field appearance="fill">
			<mat-label>Name</mat-label>
			<input matInput [formControl]="nameControl">
			<button *ngIf="nameControl.value" matSuffix mat-icon-button aria-label="Clear" (click)="nameControl.reset()">
				<mat-icon>close</mat-icon>
			</button>
			<mat-error *ngIf="nameControl.hasError('required')">
				Lesson name is <strong>required</strong>
			</mat-error>
		</mat-form-field>
	</ng-container>

	<ng-container class="lesson-cefr-content">
		<mat-form-field appearance="fill">
			<mat-label>CEFR</mat-label>
			<mat-select [(ngModel)]="data.cefr">
				<ng-container *ngFor="let cefr of cefrs">
					<mat-option [value]="cefr">{{cefr}}</mat-option>
				</ng-container>
			</mat-select>
			<mat-error *ngIf="cefrControl.hasError('required')">
				Lesson cefr is <strong>required</strong>
			</mat-error>
		</mat-form-field>
	</ng-container>

	<ng-container class="lesson-age-content">
		<mat-form-field appearance="fill">
			<mat-label>Age</mat-label>
			<mat-select [(ngModel)]="data.age">
				<ng-container *ngFor="let age of ages">
					<mat-option [value]="age">{{age}}</mat-option>
				</ng-container>
			</mat-select>
			<mat-error *ngIf="ageControl.hasError('required')">
				Lesson age is <strong>required</strong>
			</mat-error>
		</mat-form-field>
	</ng-container>

	<ng-container class="lesson-caption-content">
		<mat-form-field appearance="fill">
			<mat-label>Caption</mat-label>
			<textarea matInput [formControl]="captionControl"></textarea>
			<button *ngIf="captionControl.value" matSuffix mat-icon-button aria-label="Clear" (click)="captionControl.reset()">
				<mat-icon>close</mat-icon>
			</button>
			<mat-error *ngIf="captionControl.hasError('required')">
				Lesson caption is <strong>required</strong>
			</mat-error>
		</mat-form-field>
	</ng-container>

	<ng-container class="lesson-vocabulary-content">
		<mat-form-field appearance="fill">
			<mat-label>Vocabulary</mat-label>
			<textarea matInput [formControl]="vocabularyControl"></textarea>
			<button *ngIf="vocabularyControl.value" matSuffix mat-icon-button aria-label="Clear" (click)="vocabularyControl.reset()">
				<mat-icon>close</mat-icon>
			</button>
			<mat-error *ngIf="vocabularyControl.hasError('required')">
				Lesson vocabulary is <strong>required</strong>
			</mat-error>
		</mat-form-field>
	</ng-container>

	<ng-container class="lesson-grammar-content">
		<mat-form-field appearance="fill">
			<mat-label>Grammar</mat-label>
			<textarea matInput [formControl]="grammarControl"></textarea>
			<button *ngIf="grammarControl.value" matSuffix mat-icon-button aria-label="Clear" (click)="grammarControl.reset()">
				<mat-icon>close</mat-icon>
			</button>
			<mat-error *ngIf="grammarControl.hasError('required')">
				Lesson grammar is <strong>required</strong>
			</mat-error>
		</mat-form-field>
	</ng-container>

	<ng-container class="lesson-aim-content">
		<mat-form-field appearance="fill">
			<mat-label>Aim</mat-label>
			<textarea matInput [formControl]="aimControl"></textarea>
			<button *ngIf="aimControl.value" matSuffix mat-icon-button aria-label="Clear" (click)="aimControl.reset()">
				<mat-icon>close</mat-icon>
			</button>
			<mat-error *ngIf="aimControl.hasError('required')">
				Lesson can statement is <strong>required</strong>
			</mat-error>
		</mat-form-field>
	</ng-container>

	<ng-container class="lesson-owner-content">
		<mat-form-field appearance="fill">
			<mat-label>Owner</mat-label>
			<mat-select [(ngModel)]="data.owner">
				<ng-container *ngFor="let owner of owners">
					<mat-option [value]="owner.id">{{owner.displayName}}</mat-option>
				</ng-container>
			</mat-select>
			<mat-error *ngIf="ownerControl.hasError('required')">
				Lesson owner is <strong>required</strong>
			</mat-error>
		</mat-form-field>
	</ng-container>

	<ng-container class="lesson-type-content">
		<mat-form-field appearance="fill">
			<mat-label>Type</mat-label>
			<mat-select [(ngModel)]="data.lessonType" [formControl]="typeControl">
				<ng-container *ngFor="let type of types">
					<mat-option [value]="type">{{type}}</mat-option>
				</ng-container>
			</mat-select>
			<button *ngIf="data.lessonType" matSuffix mat-icon-button aria-label="Clear" (click)="data.lessonType=LessonType.DEFAULT">
				<mat-icon>close</mat-icon>
			</button>
			<mat-error *ngIf="ownerControl.hasError('required')">
				Lesson type is <strong>required</strong>
			</mat-error>
		</mat-form-field>
	</ng-container>

	<ng-container class="lesson-image-content">
		<mat-form-field appearance="fill">
			<mat-label>Image</mat-label>
			<input matInput [ngModel]="data.image" (ngModelChange)="changeImage($event)">
			<button *ngIf="data.image" matSuffix mat-icon-button aria-label="Clear" (click)="changeImage('')">
				<mat-icon>close</mat-icon>
			</button>
		</mat-form-field>
		<button mat-button [disabled]="data.image === ''" class="verify-button" (click)="verifyImage()">Verify Image</button>
	</ng-container>

	<ng-container>
		<app-image-uploader *ngIf="toVerify" [imageId]="imageVerify" (uploadFiles)="setFile($event)" (exists)="imageExists=true; data.image=$event"></app-image-uploader>
	</ng-container>

</div>

<div mat-dialog-actions>
	<button [disabled]="isUploading" mat-button (click)="closeDialog()">Discard</button>
	<button [disabled]="isUploading" mat-button (click)="copyLesson()" cdkFocusInitial>Copy</button>
	<mat-spinner *ngIf="isUploading" [diameter]="50"></mat-spinner>
</div>