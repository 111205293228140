export const VALID_CEFRS = ['Pre-A1', 'A1', 'A2', 'B1','B2'] as const;
export const VALID_AGES = ['2+','4+','6+', '8+', '12+'] as const;

export const VALID_ACTIVITY_AGES = [...VALID_AGES, "NoAge"];

export type Age = typeof VALID_AGES[number] | typeof VALID_ACTIVITY_AGES[number];
export type Cefr = typeof VALID_CEFRS[number];

export type Skill = 'reading' | 'listening' | 'writing' | 'speaking';
export const VALID_SKILLS = ['listening', 'reading', 'writing', 'speaking'] as const;


export const COLLECTIONS = {
	Activities: "activities",
}
