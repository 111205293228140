import { ILessonContent } from "./lesson-content.model";
import { Timestamp } from 'firebase/firestore';
import { Age, Cefr } from "../constants";


export interface IActiveLesson {
	info: ILesson;
	content: ILessonContent;
}

export interface ILesson {
	uid: string;
	image: string;
	displayName: string;
	cefr: Cefr;
	age: Age;
	caption: string;
	vocabulary: string;
	grammar: string;
	aim: string;
	owner: string;
	lessonType: ILessonType;
	version: number;
	releasePhase: string;
	isUploading: boolean;
	isContentValid?: boolean;
	invalidContent?: string[];
}

export interface ICreateLesson {
	uid?: string;
	image: string;
	displayName: string;
	cefr: string;
	age: string;
	caption: string;
	vocabulary: string;
	grammar: string;
	aim: string;
	owner: string;
	lessonType: ILessonType;
	releasePhase: string;
	version?: number;
}

export interface IUpdateLesson {
	uid?: string;
	image?: string;
	displayName?: string;
	cefr?: string;
	age?: string;
	caption?: string;
	vocabulary?: string;
	grammar?: string;
	aim?: string;
	owner?: string;
	lessonType?: ILessonType;
	version?: number;
	releasePhase?: string;
	isUploading?: boolean;
}

export enum ILessonType {
	DEFAULT = "Default",
	CUSTOM = "Custom",
}

export interface ILessonUploadData {
	lessonId: string;
	version: number;
	userId: string;
	generateVocabulary: LessonUpdateStatus;
	generateSentences: LessonUpdateStatus;
	generateDialogues: LessonUpdateStatus;
	generateTexts: LessonUpdateStatus;
	generateQuizText: LessonUpdateStatus;
	generateQuizVideo: LessonUpdateStatus;
	generateQuizImage: LessonUpdateStatus;
	generateQuizQuestion: LessonUpdateStatus;
	generateQuestions: LessonUpdateStatus;
	generateDragDrop: LessonUpdateStatus;
	generateJenga: LessonUpdateStatus;
	generateFillTheGaps: LessonUpdateStatus;
	generateFillMultichoice: LessonUpdateStatus;
	generateOrderingDialogues: LessonUpdateStatus;
	generateLessonPlanSteps: LessonUpdateStatus;
	generateDefaultDecks: LessonUpdateStatus;
	generateClassroomGames: LessonUpdateStatus;
	startTime: Timestamp;
	creationTime?: Timestamp;
	endTime?: Timestamp;
}

export const VALID_UPDATE_STATUS = ['Waiting', 'Doing', 'Done', 'ExecutionError', 'NoAudioError', 'Timeout', 'ReqError'] as const;
export type LessonUpdateStatus = typeof VALID_UPDATE_STATUS[number];

export const errorStates = new Set<LessonUpdateStatus>([
	"ExecutionError",
	"NoAudioError",
	"Timeout",
]);

export enum CloudFunctionNames {
	DIALOGUES = "generateDialogues",
	DRAG_DROP = "generateDragDrop",
	FILL_MULTICHOICE = "generateFillMultichoice",
	FILL_THE_GAPS = "generateFillTheGaps",
	JENGA = "generateJenga",
	ORDERING_DIALOGUES = "generateOrderingDialogues",
	QUESTIONS = "generateQuestions",
	QUIZ_IMAGE = "generateQuizImage",
	QUIZ_QUESTION = "generateQuizQuestion",
	QUIZ_TEXT = "generateQuizText",
	QUIZ_VIDEO = "generateQuizVideo",
	SENTENCES = "generateSentences",
	TEXTS = "generateTexts",
	VOCABULARY = "generateVocabulary",
	LESSON_PLAN_STEPS="generateLessonPlanSteps",
	DECKS = "generateDefaultDecks",
	GAMES = "generateClassroomGames"
}
