<div class="content-view">
	<lib-toastify-toast-container position="bottom-right"></lib-toastify-toast-container>
	<div class="header">
		<mat-toolbar class="header" color="primary">
			<button mat-icon-button color="white" (click)="home()">
				<mat-icon>home</mat-icon>
			</button>
			<div class="start-header">
				<h1 class="title">Dungeons</h1>
			</div>
			<div class="end-header">
				<button mat-mini-fab color="white" (click)="logout()">
					<mat-icon>logout</mat-icon>
				</button>
			</div>
		</mat-toolbar>
	</div>

	<mat-tab-group *ngIf="draftDungeons; else loading">

		<mat-tab label="Draft">
			<app-dungeon-list [dungeons]="draftDungeons" [oppositeDungeons]="liveDungeons" [isDraft]="true" (dungeonUpdated)="loadDungeons()"></app-dungeon-list>
		</mat-tab>

		<mat-tab label="Live">
			<app-dungeon-list [dungeons]="liveDungeons" [oppositeDungeons]="draftDungeons" [isDraft]="false" (dungeonUpdated)="loadDungeons()"></app-dungeon-list>
		</mat-tab>

	</mat-tab-group>

</div>

<ng-template #loading>
	<div style="position: inherit;" class="loading">
		<mat-spinner></mat-spinner>
	</div>
</ng-template>