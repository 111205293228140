<div class="dialog-container">
  <div mat-dialog-title class="dialog-title">{{data.uid === "" ? "CRIAR DUNGEON" : "EDITAR DUNGEON"}}</div>

  <div *ngIf="loading" class="spinner-container">
    <mat-spinner></mat-spinner>
  </div>

  <div class="form-container" *ngIf="!loading && !selectingLessons">
    <mat-form-field appearance="fill">
      <mat-label>Image</mat-label>
      <input matInput [(ngModel)]="data.imageId" (ngModelChange)="changeImage($event)" />
      <button *ngIf="data.imageId" matSuffix mat-icon-button aria-label="Clear" (click)="changeImage('')">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <button mat-button [disabled]="data.imageId === ''" class="verify-button" (click)="verifyImage()">Verify
      Image</button>

    <app-image-uploader *ngIf="toVerify" [imageId]="data.imageId" (uploadFiles)="setFile($event)"
      (exists)="imageExists = true; data.imageId = $event">
    </app-image-uploader>

    <mat-form-field appearance="outline">
      <mat-label>Nome</mat-label>
      <input matInput [(ngModel)]="data.name" [formControl]="nameControl" placeholder="Nova Dungeon" />
      <button *ngIf="data.name" matSuffix mat-icon-button aria-label="Clear" (click)="data.name=''">
        <mat-icon>close</mat-icon>
      </button>
      <mat-error *ngIf="nameControl.hasError('required')">
        Dungeon name is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Owner</mat-label>
      <mat-select [(ngModel)]="data.owner" [formControl]="ownerControl">
        <mat-option [disabled]="true">
          <div class="search-container">
            <input matInput placeholder="Pesquisar..." [(ngModel)]="searchTerm"
              (focus)="filterOptions(owners, filteredOwners, 'owners')"
              (ngModelChange)="filterOptions(owners, filteredOwners, 'owners')" (click)="$event.stopPropagation()" />
            <button *ngIf="searchTerm" mat-icon-button (click)="clearSearch(owners, filteredOwners)"
              aria-label="Clear search">
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </mat-option>
        <mat-option *ngFor="let owner of filteredOwners" [value]="owner.id">
          {{ owner.displayName }}
        </mat-option>
      </mat-select>
      <button *ngIf="data.owner" matSuffix mat-icon-button aria-label="Clear" (click)="data.owner=''">
        <mat-icon>close</mat-icon>
      </button>
      <mat-error *ngIf="ownerControl.hasError('required')">
        Dungeon owner is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <ng-container>
      <mat-label>Lições</mat-label>
      <div class="lesson-preview">
        <div *ngIf="selectedLessons.length == 0" class="lesson-item empty">
          Nenhuma lição selecionada
        </div>
        <div *ngFor="let lesson of selectedLessons" class="lesson-box-item">
          <span>{{ lesson.displayName }}</span>
        </div>
      </div>
      <button mat-button color="primary" (click)="selectingLessons = true">
        Selecionar Lições
      </button>
    </ng-container>

    <mat-form-field appearance="outline">
      <mat-label>Idade</mat-label>
      <mat-select [(ngModel)]="data.age" [formControl]="ageControl">
        <mat-option *ngFor="let age of ageValues" [value]="age">
          {{ age }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="ageControl.hasError('required')">
        Dungeon age is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Recommendation</mat-label>
      <mat-select [(ngModel)]="data.recommendation.type">
        <mat-option *ngFor="let type of algTypes" [value]="type">
          {{ type }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Version</mat-label>
      <input matInput disabled [ngModel]="data.version" />
    </mat-form-field>


  </div>

  <div *ngIf="selectingLessons" class="lesson-selection">
    <div class="available-lessons">
      <mat-form-field appearance="fill">
        <mat-label>Search Lessons</mat-label>
        <input matInput placeholder="Search available lessons..." [(ngModel)]="lessonSearchTerm"
        (ngModelChange)="filterLessons()"/>
      </mat-form-field>
      <div class="lesson-list">
        <div *ngFor="let lesson of filteredContent" class="lesson-item">
          <span>{{ lesson.displayName }}</span>
          <button mat-icon-button color="primary" (click)="addLesson(lesson)">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div class="selected-lessons">
      <h3>Selected Lessons</h3>
      <input matInput disabled />
      <div class="lesson-list">
        <div *ngFor="let lesson of selectedLessons" class="lesson-item">
          <span>{{ lesson.displayName }}</span>
          <button mat-icon-button color="warn" (click)="removeLesson(lesson)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="dialog-actions">
    <button *ngIf="!selectingLessons" mat-button color="primary" (click)="onCloseClick()">Fechar</button>
    <button *ngIf="!selectingLessons" mat-button color="primary" [disabled]="isUploading"
      (click)="onSaveClick()">Salvar</button>
    <mat-spinner *ngIf="isUploading" [diameter]="50"></mat-spinner>

    <button *ngIf="selectingLessons" mat-button color="primary" (click)="selectingLessons = false">Voltar</button>
  </div>

</div>