import { Injectable } from "@angular/core";
import { Observable, of, switchMap } from "rxjs";
import { CreateDungeon, Dungeon, UpdateDungeon } from "../models/dungeon.model";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: 'root',
})
export class DungeonService {

	constructor(
		private http: HttpClient,

	) {}

	public getDraftDungeons(): Observable<Dungeon[]> {
		return this.http.get<Dungeon[]>(`${environment.SERVICES.LESSON}dungeons-draft`)
			.pipe(
				switchMap(res => {
					return of(res);
				})
			)
	}

	public getLiveDungeons(): Observable<Dungeon[]> {
		return this.http.get<Dungeon[]>(`${environment.SERVICES.LESSON}dungeons`)
			.pipe(
				switchMap(res => {
					return of(res);
				})
			)
	}

	public createDungeon(data: CreateDungeon): Observable<{uid: string}> {
		return this.http.post<{uid: string}>(`${environment.SERVICES.LESSON}dungeons-draft`, data);
	}

	public removeDungeon(uid: string): Observable<void> {
		return this.http.delete<void>(`${environment.SERVICES.LESSON}dungeons-draft/${uid}`);
	}

	public updateDungeon(uid: string, dungeon: Dungeon) {
		const updateInfo: UpdateDungeon = Object.assign({}, dungeon);

		delete updateInfo.uid;

		return this.http.put<void>(`${environment.SERVICES.LESSON}dungeons-draft/${uid}`, updateInfo);
	}

	public pushDungeonsToLive(uids: string[]) {
		return this.http.post<void>(`${environment.SERVICES.LESSON}dungeons`, { dungeonIds: uids });
	}

	public publishDungeon(uid: string, phase: boolean): Observable<boolean> {
		const updateInfo = {
			releasePhase: phase ? 'Published' : 'Not Published'
		};

		return this.http.put<boolean>(`${environment.SERVICES.LESSON}dungeons/${uid}`, updateInfo);
	}
}
