import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { of, Subscription, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface UserAuthToken {
	authToken: string;
}

@Injectable({
	providedIn: 'root'
})
export class MetagamelinkService implements OnDestroy {


	private gameSsoToken !: UserAuthToken | null;
	private tokenSub: Subscription;

	constructor(
		private readonly auth: AngularFireAuth,
		private readonly http: HttpClient,
	) {
		this.tokenSub = this.auth.idToken.pipe(
			switchMap((token) => {
				if (token) {
					return this.http.get<UserAuthToken>(`${environment.SERVICES.MANAGEMENT}/auth/auth-token`)
				} else {
					return of(null)
				}
			})
		).subscribe(token => {
			this.gameSsoToken = token;
		});
	}

	ngOnDestroy(): void {
		this.tokenSub.unsubscribe();
	}

	public getClassroomGameUrl(lessonId: string, gameType: string, levelId: string, version: number): string {
		const actionName = 'classroomGame';
		const addssoToken = true;
		const paramMap = new Map<string, string>(
		  [
			['classId', "fake_id"],
			['lessonId', lessonId],
			['version', String(version)],
			['gameType', gameType],
			['level', levelId],
		  ],
		);

		return this.buildActionUrl(addssoToken, actionName, paramMap, environment.METAGAME_URL);
	  }

	testLesson(lessonId: string, isDraft: boolean) {
		const action = 'testLesson';
		const addssoToken = true;
		const paramMap = new Map<string, string>(
			[
				["classId", "fake_id"],
				["lessonId", lessonId],
				["isDraft", String(isDraft)],
			]
		)

		const url = this.buildActionUrl(addssoToken, action, paramMap, environment.METAGAME_URL);
		window.open(url, '_blank');
	}

	testDungeon(dungeonId: string, isDraft: boolean) {
		const action = 'testDungeon';
		const addssoToken = true;
		const paramMap = new Map<string, string>(
			[
				["classId", "fake_id"],
				["dungeonId", dungeonId],
				["isDraft", String(isDraft)],
			]
		)

		const url = this.buildActionUrl(addssoToken, action, paramMap, environment.TEMP_GAME_URL);
		window.open(url, '_blank');
	}

	testLessonPlan(planId: string, lessonId: string) {
		const action = 'startPlanDraft';
		const addSsoToken = true;
		const paramMap = new Map<string, string>(
			[
				['planId', planId],
				['classId', "fake_id"],
				['lessonId', lessonId]
			]
		);
		const url = this.buildActionUrl(addSsoToken, action, paramMap, environment.SLIDE_PLAYER_URL);
		window.open(url, '_blank');
	}

	private buildActionUrl(addSsoToken: boolean, action: string, paramMap: Map<string, string>, destUrl: string) {
		const url = new URL(destUrl);
		const parameters: { [key: string]: string } = {};
		paramMap.forEach((value, key) => {
			parameters[key] = value;
		});

		url.searchParams.append('action', JSON.stringify({
			action_name: action,
			parameters,
		}));

		if (addSsoToken && this.gameSsoToken) {
			url.searchParams.append('ssoToken', this.gameSsoToken.authToken);
		}

		return url.href;
	}
}
