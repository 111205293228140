import { Age } from "../constants";
import { CognitionPair, IActivityContent } from "./lesson.model";

export const DUNGEON_TYPES = ["Homework", "Arcade", "Revisão"] as const;
export type DungeonType = typeof DUNGEON_TYPES[number];

export const STAR_VALUES = [1, 2, 3, 4] as const;
export type DungeonStars = typeof STAR_VALUES[number];

export const ALG_TYPES = ["Classic"];
export type RecommendationType = typeof ALG_TYPES[number];

export const CHEST_RARITY = ["common", "rare", "epic"];
export type DungeonChestRarity = typeof CHEST_RARITY[number];

export const DUNGEON_CONTENT_TYPES = ["Activity"]
export type DungeonContentType = typeof DUNGEON_CONTENT_TYPES[number];

export interface CreateDungeon {
	uid?: string;
	version?: number;
	imageId: string;
	name: string;
	lessons: DungeonLesson[];
	age: Age;
	owner: string;
	recommendation: RecommendationAlgorithm;
	releasePhase: string;
}

export interface UpdateDungeon {
	uid?: string;
	imageId: string;
	name: string;
	lessons: DungeonLesson[];
	age: Age;
	owner: string;
	recommendation: RecommendationAlgorithm;
	version?: number;
}

export interface Dungeon extends DungeonData {
	uid: string;
}

export interface DungeonData {
	imageId: string;
	name: string;
	lessons: DungeonLesson[];
	age: Age;
	owner: string;
	recommendation: RecommendationAlgorithm;
	version: number;
	isContentValid: boolean;
	releasePhase: string;
	invalidContent?: string[];
}

export interface DungeonLesson {
	lessonId: string;
	displayName: string;
	age: string;
	version: number;
}

export interface Recommendation {
	type: RecommendationType;
}

export interface ClassicRecommendation extends Recommendation {
	type: "Classic";
}

export interface CustomRecommendation {
	type: "Custom";
	stars: DungeonStars;
	contentByCognition: CognitionPair;
	starConfig: DungeonStar[];
}

export interface DungeonStar {
	life: number;
	rewards: DungeonReward[];
	content: DungeonContent[];
}

export interface DungeonReward {
	index: number;
	type: DungeonChestRarity;
	amount: number;
}

export interface DungeonContent {
	type: DungeonContentType;
	xp: number;
	content: DungeonCustomContent;
}

export interface ActivityContent {
	uid: string;
	lessonId: string;
}

export type DungeonCustomContent = IActivityContent;

export type RecommendationAlgorithm = ClassicRecommendation | CustomRecommendation;
