import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DungeonComponent } from "./dungeon.component";
import { RouterModule } from "@angular/router";
import { AngularToastifyModule } from "angular-toastify";
import { NavbarRoutingModule } from "../navbar-routing.module";
import { SharedModule } from "src/app/shared/components/shared.module";
import { MatIconModule } from "@angular/material/icon";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from "@angular/material/button";
import { DungeonListComponent } from "./dungeon-list/dungeon-list.component";
import { MatTabsModule } from "@angular/material/tabs";
import { AddDungeonDialog } from "./add-dungeon/add-dungeon.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
	imports: [
		NavbarRoutingModule,
		CommonModule,
		RouterModule,
		AngularToastifyModule,
		SharedModule,
		MatIconModule,
		MatToolbarModule,
		MatButtonModule,
		MatTabsModule,
		MatFormFieldModule,
		MatInputModule,
		MatSelectModule,
		MatProgressSpinnerModule,
		MatCheckboxModule,
		MatPaginatorModule,
		MatTableModule,
		MatTooltipModule,
		FormsModule,
		ReactiveFormsModule,
	],
	declarations: [
		DungeonComponent,
		DungeonListComponent,
		AddDungeonDialog,
	],
})
export class DungeonModule {}
