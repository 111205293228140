import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ToastService } from "angular-toastify";
import { Dungeon } from "src/app/shared/models/dungeon.model";
import { AuthService } from "src/app/shared/services/auth.service";
import { DungeonService } from "src/app/shared/services/dungeon.service";

@Component({
	selector: "app-dungeon",
	templateUrl: "./dungeon.component.html",
	styleUrls: ["./dungeon.component.scss"],
})
export class DungeonComponent implements OnInit {
	draftDungeons!: Dungeon[];
	liveDungeons!: Dungeon[];

	constructor(
		private router: Router,
		private authService: AuthService,
		private dungeonService: DungeonService,
		private toastService: ToastService,
	) {}

	ngOnInit() {
		this.loadDungeons();
	}

	home() {
		this.router.navigate(["navbar"]);
	}

	async logout() {
		let data = await this.authService.logout();
		if (data) {
			this.router.navigate(["auth/login"]);
		}
	}

	loadDungeons() {
		this.loadDraftDungeons();
		this.loadLiveDungeons();
	}

	private loadDraftDungeons() {
		this.dungeonService.getDraftDungeons()
			.subscribe({
				next: (res) => {
					this.draftDungeons = res;
				},
				error: (err) => {
					console.error(err);
					this.toastService.error('Failed to load draft dungeons');
					this.draftDungeons = [];
				}
			});
	}

	private loadLiveDungeons() {
		this.dungeonService.getLiveDungeons()
			.subscribe({
				next: (res) => {
					this.liveDungeons = res;
				},
				error: (err) => {
					console.error(err);
					this.toastService.error('Failed to load live dungeons');
					this.liveDungeons = [];
				}
			})
	}
}
