<div mat-dialog-title color="primary">
	CREATE CUSTOM CONFIG
</div>

<div mat-dialog-content class='dialog-content'>

	<mat-stepper linear>
		<mat-step>
			<ng-template matStepLabel>General Information</ng-template>
			<div class="step-container">
				<div class="step-content">
					<ng-container class="lesson-name-content">
						<mat-form-field appearance="fill">
							<mat-label>Stars</mat-label>
							<input matInput type="number" [(ngModel)]="totalStars" [formControl]="starsControl"
							min="1" max="4" (input)="checkStars($event)" (change)="onChangeStars()">
							<mat-error *ngIf="starsControl.hasError('required')">
								Star amount is <strong>required</strong>
							</mat-error>
						</mat-form-field>
					</ng-container>
				</div>
				<div class="step-btn-container" style="justify-content: flex-end;">
					<button mat-raised-button color="primary" [disabled]="totalStars <= 0 || totalStars > 4" matStepperNext>Next</button>
				</div>
			</div>
		</mat-step>
		<mat-step>
			<ng-template matStepLabel>Star Informations</ng-template>
			<div class="step-container">
				<div class="step-content">
					<div class="stars-paginator">
						<button mat-icon-button (click)="goToStar(currentStar - 1)" [disabled]="currentStar === 0">
							&lt; <!-- Left Arrow -->
						</button>
						<p>{{ currentStar + 1 }} of {{ totalStars }}</p>
						<button mat-icon-button (click)="goToStar(currentStar + 1)"
							[disabled]="currentStar === totalStars - 1">
							&gt; <!-- Right Arrow -->
						</button>
					</div>

					<form [formGroup]="starsForm">
						<div formArrayName="stars">
							<ng-container *ngFor="let starGroup of stars.controls; let i = index;">
								<ng-container *ngIf="i === currentStar">
									<ng-container [formGroupName]="i">
										<div class="lifes">
											<mat-form-field appearance="fill">
												<mat-label>Lifes</mat-label>
												<input matInput type="number" formControlName="life">
												<mat-error *ngIf="starGroup.get('life')?.errors?.required">
													Life amount is <strong>required</strong>
												</mat-error>
											</mat-form-field>
										</div>
									</ng-container>
								</ng-container>
							</ng-container>
						</div>

						<div class="content-select-container">
							<div class="activity-table-container">
								<mat-form-field appearance="standard">
									<mat-label>Filter</mat-label>
									<input matInput (keyup)="applyFilter($event)" placeholder="Ex. Activity x" #input>
								</mat-form-field>

								<table mat-table [dataSource]="activitiesDataSource">

									<ng-container matColumnDef="description">
										<th mat-header-cell *matHeaderCellDef> Type </th>
										<td mat-cell *matCellDef="let activity"> {{activity.description}} </td>
									</ng-container>

									<ng-container matColumnDef="text">
										<th mat-header-cell *matHeaderCellDef> Text </th>
										<td mat-cell *matCellDef="let activity"> {{activity.text}} </td>
									</ng-container>

									<ng-container matColumnDef="skill">
										<th mat-header-cell *matHeaderCellDef> Skill </th>
										<td mat-cell *matCellDef="let activity"> {{activity.skill}} </td>
									</ng-container>

									<ng-container matColumnDef="game">
										<th mat-header-cell *matHeaderCellDef> Game </th>
										<td mat-cell *matCellDef="let activity"> {{activity.game}} </td>
									</ng-container>

									<ng-container matColumnDef="pick">
										<th mat-header-cell *matHeaderCellDef> Pick </th>
										<td mat-cell *matCellDef="let activity">
											<button mat-mini-fab color="primary"
												[disabled]="isActivityPicked(currentStar, activity)"
												(click)="addActivity(activity)">
												<mat-icon>add</mat-icon>
											</button>
										</td>
									</ng-container>

									<tr mat-header-row *matHeaderRowDef="activitiesColumn"></tr>
									<tr mat-row *matRowDef="let myRowData; columns: activitiesColumn"></tr>
								</table>
								<mat-paginator [pageSizeOptions]="[5]" showFirstLastButtons #activitiesPaginator>
								</mat-paginator>

							</div>

							<div class="activity-list-container">
								<ng-container *ngIf="currentStar >= 0">
									<div cdkDropList class="list" (cdkDropListDropped)="drop($event, currentStar)">
										<div class="list-item"
											*ngFor="let data of getGroupActivities(currentStar); let activityIndex = index;"
											cdkDrag
											[ngClass]="{ 'highlight-red': isActivityInvalid(data.uid) }">
											<div class="item-index">{{ activityIndex + 1 }}</div>
											<div class="item-text" *ngIf="!isActivityInvalid(data.uid)">
												{{ getActivityDescription(data.uid) }}
											</div>
											<div class="activity-text" *ngIf="!isActivityInvalid(data.uid)">
												{{ getActivityText(data.uid) }}
											</div>
											<div class="item-text" *ngIf="isActivityInvalid(data.uid)">
												{{ data.uid }}
											</div>
											<div class="item-remove">
												<button mat-icon-button color="primary"
													(click)="removeActivity(currentStar, activityIndex)">
													<mat-icon>delete</mat-icon>
												</button>
											</div>
										</div>
									</div>
								</ng-container>
							</div>

							<div class="chest-list-container">
								<ng-container *ngIf="currentStar >= 0">
									<div class="list">
										<div class="list-item"
											*ngFor="let chest of getGroupChests(currentStar); let chestIndex = index">
											<div class="item-index">{{ chestIndex + 1 }}</div>
											<div class="item-text" style="display: flex; flex-direction: row; justify-content: space-between;">
												<img *ngIf="chest.type" [src]="getRarityIcon(chest.type)"
													class="rarity-icon" />
												<mat-select [(value)]="chest.type"
													(selectionChange)="onRarityChange(chest.type, chestIndex)">
													<mat-option selected [value]="">Select Rarity</mat-option>
													<mat-option *ngFor="let rarity of ChestRarity" [value]="rarity">
														<img [src]="getRarityIcon(rarity)" class="rarity-icon">
														<span class="option-text">{{ rarity }}</span>
													</mat-option>
												</mat-select>
											</div>
										</div>
									</div>
								</ng-container>
							</div>
						</div>
					</form>
				</div>
				<div class="step-btn-container">
					<button mat-raised-button color="primary" matStepperPrevious>Previous</button>
					<button mat-raised-button color="accent" matStepperNext [disabled]="isUploading" (click)="save()">Save</button>
				</div>
			</div>
		</mat-step>
	</mat-stepper>
</div>