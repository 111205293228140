import { NgModule } from "@angular/core";
import { AngularFireAuthGuard } from "@angular/fire/compat/auth-guard";
import { RouterModule } from "@angular/router";

import { Routes } from "@angular/router";
import { ClassroomGamesResolver } from "../shared/services/resolver/classroom-games-resolver.service";
import { ImportClassroomGamesResolver } from "../shared/services/resolver/import-classroom-games-resolver.service";
import { ImportGroupsResolver } from "../shared/services/resolver/import-group-resolver.service";
import { ImportLessonResolver } from "../shared/services/resolver/import-lesson-resolver.service";
import { LessonResolver } from "../shared/services/resolver/lesson-resolver.service";
import { ClassroomGamesComponent } from "./classroom-games/classroom-games.component";
import { ActivityDescriptionComponent } from "./activity-description/activity-description.component";
import { LessonComponent } from "./lesson/lesson.component";
import { LessonsComponent } from "./lessons/lessons.component";
import { NavbarComponent } from "./navbar.component";
import { ProgramsComponent } from "./programs/programs.component";
import { StepEditorComponent } from "./steps/step-editor/step-editor.component";
import { StepsComponent } from "./steps/steps.component";
import { PlacementQuestionsComponent } from "./placement-questions/placement-questions.component";
import { DungeonComponent } from "./dungeon/dungeon.component";

export const routes: Routes = [
	{
		path: "",
		component: NavbarComponent,
	},
	{
		path: "lessons",
		component: LessonsComponent,
		canActivate: [AngularFireAuthGuard],
	},
	{
		path: "lessons/:lessonId",
		component: LessonComponent,
		canActivate: [AngularFireAuthGuard],
		resolve: { lessonData: LessonResolver },
	},
	{
		path: "lessons/:lessonId/:mode/games",
		component: ClassroomGamesComponent,
		canActivate: [AngularFireAuthGuard],
		resolve: { gamesResolverInfo: ClassroomGamesResolver },
	},
	{
		path: "activity-description",
		component: ActivityDescriptionComponent,
		canActivate: [AngularFireAuthGuard],
	},
	{
		path: "programs",
		component: ProgramsComponent,
		canActivate: [AngularFireAuthGuard],
	},
	{
		path: "steps",
		component: StepsComponent,
		canActivate: [AngularFireAuthGuard],
	},
	{
		path: "steps/create",
		component: StepEditorComponent,
		canActivate: [AngularFireAuthGuard],
	},
	{
		path: "steps/:stepId",
		component: StepEditorComponent,
		canActivate: [AngularFireAuthGuard],
	},
	{
		path: "placement-questions",
		component: PlacementQuestionsComponent,
		canActivate: [AngularFireAuthGuard],
	},
	{
		path: "dungeon",
		component: DungeonComponent,
		canActivate: [AngularFireAuthGuard],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class NavbarRoutingModule {}
