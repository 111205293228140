import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ToastService } from 'angular-toastify';
import { Subscription, catchError, combineLatest, forkJoin, switchMap } from 'rxjs';
import { IActivity } from 'src/app/shared/models/activities.model';
import { IActivityDescription } from 'src/app/shared/models/activity-description.model';
import { CognitionPair, IActivityContent, IActivityContentList, IChestRarity, ICustomChest, ICustomConfig, ICustomConfigContent, ICustomStar, ILesson, IUpdateLesson } from 'src/app/shared/models/lesson.model';
import { ActivityDescriptionService } from 'src/app/shared/services/activity-description.service';
import { FirestoreService } from 'src/app/shared/services/firestore.service';
import { LessonService } from 'src/app/shared/services/lesson.service';

@Component({
  selector: 'app-custom-config',
  templateUrl: './custom-config.component.html',
  styleUrls: ['./custom-config.component.scss']
})
export class CustomConfigDialog implements OnInit, OnDestroy, AfterViewInit {

	@ViewChild('activitiesPaginator') activitiesPaginator !: MatPaginator;

	readonly ChestRarity = Object.values(IChestRarity);
	private updateSubscription!: Subscription;

	currentStar = 0;
	totalStars: number = 1;
	starsControl = new FormControl(null, Validators.required);

	activitiesColumn = ['description', 'text', 'skill', 'game', 'pick'];

	activities!: IActivity[];
	activitiesDataSource!: MatTableDataSource<IActivity>

	activityDescriptions: IActivityDescription[] = [];

	starsForm!: FormGroup;

	isUploading: boolean = false;

  	constructor(
		@Inject(MAT_DIALOG_DATA) public data: ILesson,
		private dialogRef: MatDialogRef<CustomConfigDialog>,
		private toastService: ToastService,
		private firestore: FirestoreService,
		private lessonService: LessonService,
		private descriptionService: ActivityDescriptionService,
		private fb: FormBuilder
	) {
		this.starsForm = this.fb.group({
			stars: this.fb.array([]),
		});

		this.addStars();
	}

	ngOnInit() {
		if (this.data.customConfig != null) {
			this.stars.clear();

			this.data.customConfig.starConfig.forEach((starConfig) => {
				this.stars.push(this.fb.group({
					life: new FormControl(starConfig.life, Validators.required),
					content: new FormControl(
						starConfig.customContent.map(content =>
							{ return {type: content.type, uid: content.content.uid} as IActivityContentList}
						),
						Validators.required
					),
					chests: new FormControl(
						Array(starConfig.customContent.length).fill({type: ""}).map((chest, index) => {
							for (let reward of starConfig.rewards) {
								if (index == reward.index) {
									return {type: reward.type};
								}
							}
							return {type: ""};
						}),
						Validators.required
					),
				}))
			});
		}

		combineLatest({
			descriptions: this.descriptionService.getActivityDescriptions(),
			activities: this.firestore.getLessonActivitiesByVersion(this.data.uid, this.data.version)
		}).subscribe(({ descriptions, activities } : {descriptions: IActivityDescription[], activities: IActivity[]}) => {
			this.activityDescriptions = descriptions;
			this.activities = activities;

			activities = activities.map((activity : IActivity) => {
				activity.description = this.getDescriptionName(activity.description);
				activity.text = activity.text || 'Vocabulary';
				return activity;
			});

			this.activitiesDataSource = new MatTableDataSource(activities);
			this.activitiesDataSource.paginator = this.activitiesPaginator;
		});
	}

	ngOnDestroy(): void {
		if (this.updateSubscription) {
			this.updateSubscription.unsubscribe();
		}
	}

	ngAfterViewInit(): void {

	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.activitiesDataSource.filter = filterValue.trim().toLowerCase();

		if (this.activitiesDataSource.paginator) {
			this.activitiesDataSource.paginator.firstPage();
		}
	}

	isActivityPicked(currentStar: number, activity: IActivity) {
		const content = this.stars.controls[currentStar].get("content")?.value;
		return content.some((item: IActivityContentList) => item.uid === activity.id);
	}

	addActivity(activity: IActivity) {
		const content = this.stars.controls[this.currentStar].get("content")?.value;
		const chests = this.stars.controls[this.currentStar].get("chests")?.value;

		const activityExists = content.some((item: IActivityContentList) => item.uid === activity.id);

		if (!activityExists) {
			content.push({
				type: "Activity",
				uid: activity.id,
			} as IActivityContentList);

			chests.push({
				type: "",
			});
		}
	}

	drop(event: CdkDragDrop<IActivityContentList[]>, currentStar: number) {
		moveItemInArray(this.stars.controls[currentStar].get("content")?.value, event.previousIndex, event.currentIndex);
	}

	getGroupActivities(currentStar: number) : IActivityContentList[] {
		return this.stars.controls[currentStar].get("content")?.value;
	}

	getActivityDescription(uid: string) : string {
		if (!this.activities) return "";

		const activity = this.activities.find(activity => activity.id === uid);

		if (activity) {
			return this.getDescriptionName(activity.description);
		} else {
			return "";
		}
	}

	getActivityText(uid: string) : string {
		if (!this.activities) return "";

		const activity = this.activities.find(activity => activity.id === uid);

		if (activity) {
			return activity.text;
		} else {
			return "";
		}
	}

	isActivityInvalid(uid: string) {
		return this.data.invalidContent?.includes(uid);
	}

	removeActivity(currentStar: number, activityIndex: number) {
		const data = this.stars.controls[currentStar];
		data.get("content")?.value.splice(activityIndex, 1);
		data.get("chests")?.value.splice(data.get("chests")?.value.length - 1, 1);
	}

	getDescriptionName(description: string) {
		let data = this.activityDescriptions.find(data => data.description === description);
		return data?.name ?? description;
	}

	getCurrentRarity(chestIndex: number, currentStar: number) : string{
		return this.stars.controls[currentStar].get("chests")?.value[chestIndex].type;
	}

	onRarityChange(newRarity: string, chestIndex: number): void {
		const chests = this.getGroupChests(this.currentStar);

		chests.forEach((chest: { type: string }, index: number) => {
		  if (index !== chestIndex && chest.type === newRarity) {
			chest.type = '';
		  }
		});

		chests[chestIndex].type = newRarity;
	  }

	getGroupChests(currentStar: number) {
		return this.stars.controls[currentStar].get("chests")?.value;
	}

	getRarityIcon(rarity: string): string {
		switch (rarity) {
			case 'common': return 'assets/chests/chest_common.svg';
			case 'rare': return 'assets/chests/chest_rare.svg';
			case 'epic': return 'assets/chests/chest_epic.svg';
		  	default: return '';
		}
	  }

	onChangeStars() {
		const starsArray = this.stars;

		if (this.totalStars > starsArray.length) {
			for (let i = starsArray.length; i < this.totalStars; i++) {
			  this.stars.push(this.createStarsGroup());
			}
		} else if (this.totalStars < starsArray.length) {
			for (let i = starsArray.length - 1; i >= this.totalStars; i--) {
			  this.stars.removeAt(i);
			}
		}

		this.currentStar = Math.max(0, Math.min(this.totalStars - 1, this.currentStar));
	}

	checkStars(event: any): void {
		const inputValue = event.target.value;

		if (inputValue < 1) {
		  event.target.value = 1;
		  this.totalStars = 1;
		} else if (inputValue > 4) {
		  event.target.value = 4;
		  this.totalStars = 4;
		}
	  }

	goToStar(index: number) {
		if (index >= 0 && index < this.totalStars) {
			this.currentStar = index;
		}
	}

	get stars(): FormArray {
		return this.starsForm.get("stars") as FormArray;
	}

	addStars() {
		this.stars.push(this.createStarsGroup());
	}

	createStarsGroup(): FormGroup {
		return this.fb.group({
			life: new FormControl(3, Validators.required),
			content: new FormControl([], Validators.required),
			chests:  new FormControl([], Validators.required),
		});
	}

	save() {
		this.isUploading = true;
		let canUpload = true;

		let index = 0;
		for (let star of this.stars.controls) {
			const life = star.get("life")?.value;
			const chests = star.get("chests")?.value;
			const activities = star.get("content")?.value;

			if (!life || life < 0) {
				canUpload = false;
				this.toastService.error(`Star ${index + 1} life is smaller than 0.`);
			}

			if (!chests || chests.filter((chest: any) => chest.type).length === 0) {
				canUpload = false;
				this.toastService.error(`There are no chests for star ${index + 1}.`);
			}

			const invalidActivities = activities.map((content: IActivityContentList) => this.isActivityInvalid(content.uid))
				.filter((isInvalid: boolean) => isInvalid === true);


			if (!activities || activities.length <= 0) {
				canUpload = false;
				this.toastService.error(`There are no activities in star ${index + 1}.`);
			}

			if (!activities || invalidActivities.length > 0) {
				canUpload = false;
				this.toastService.error(`There are invalid activities in star ${index + 1}.`);
			}

			index++;
		}

		if (!canUpload) {
			this.isUploading = false;
			this.toastService.error("There are one or more errors in config form. Please fix them and try again.");
			return;
		}

		let contentByCognition: CognitionPair = {
			"0": { listening: 0, reading: 0, speaking: 0, writing: 0 },
			"1": { listening: 0, reading: 0, speaking: 0, writing: 0 },
			"2": { listening: 0, reading: 0, speaking: 0, writing: 0 },
			"3": { listening: 0, reading: 0, speaking: 0, writing: 0 },
			"4": { listening: 0, reading: 0, speaking: 0, writing: 0 },
		};

		for (let ctrl of this.stars.controls) {
			for (let activityContent of ctrl.get("content")?.value) {
				const data = activityContent as IActivityContentList;
				const activity = this.activities.find(act => act.id == data.uid);

				if (!activity) continue;

				contentByCognition[activity.cognition][activity.skill] += 1;
			}
		}

		let customConfig: ICustomConfig = {
			stars: this.totalStars,
			starConfig: this.stars.controls.map((ctrl, index) => {
				return {
					life: ctrl.get("life")?.value,
					rewards: ctrl.get("chests")?.value
						.map((data : {type: string}, index: number) => {
							return {
								type: data.type,
								index,
							} as ICustomChest;
						})
						.filter((item : any) => item.type),
						customContent: ctrl.get("content")?.value.map((activity : IActivityContentList) => {
						return {
							type: activity.type,
							content: {
								uid: activity.uid,
							}
						} as ICustomConfigContent;
					}),
				} as ICustomStar;
			}),
			contentByCognition,
		};

		const updateInfo: IUpdateLesson = {
			customConfig,
			isContentValid: true,
			invalidContent: [],
		}

		this.updateSubscription = this.lessonService
		.saveLessonInfo(this.data.uid, updateInfo)
		.pipe()
		.subscribe({
			next: () => {
				this.data.customConfig = updateInfo.customConfig;
				this.data.invalidContent = [];
				this.data.isContentValid = true;
				this.toastService.success("Custom Config uploaded successfully.")
				this.dialogRef.close(this.data as ILesson)
			},
			error: (error) => {
				console.log(error);
				this.isUploading = false;
				this.toastService.error("Failed to update config.");
			},
		})
	}

}
