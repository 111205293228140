import {
	AfterViewInit,
	Component,
	Input,
	Output,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
	ViewChild,
	EventEmitter,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AddDungeonDialog } from "../add-dungeon/add-dungeon.component";
import { CreateDungeon, Dungeon, DungeonData } from "src/app/shared/models/dungeon.model";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Owner } from "src/environments/environment.model";
import { IAccount } from "src/app/shared/models/account.model";
import { AccountStore } from "src/app/shared/services/stores/account.store.service";
import { Observable, Subscription, catchError, forkJoin, of } from "rxjs";
import { environment } from "src/environments/environment";
import { ToastService } from "angular-toastify";
import { DeleteConfirmationDialog } from "src/app/shared/components/delete-confirmation/delete-confirmation.component";
import { DungeonService } from "src/app/shared/services/dungeon.service";
import { ContentValidationDialog } from "../../lessons/content-validation/content-validation.component";
import { MetagamelinkService } from "src/app/shared/services/metagamelink.service";

@Component({
	selector: "app-dungeon-list",
	templateUrl: "./dungeon-list.component.html",
	styleUrls: ["./dungeon-list.component.scss"],
})
export class DungeonListComponent
	implements AfterViewInit, OnInit, OnChanges, OnDestroy
{
	@Input("dungeons") dungeons!: Dungeon[];
	@Input("oppositeDungeons") oppositeDungeons!: Dungeon[];
	@Input("isDraft") isDraft!: boolean;
	@Output("dungeonUpdated") dungeonUpdate = new EventEmitter<boolean>();

	displayedColumns: string[] = [
		"name",
		"actions",
		"status",
		"image",
		`lessons`,
		"age",
		"recommendation",
		"owner",
		"version",
	];

	@ViewChild(MatPaginator) paginator!: MatPaginator;
	defaultPageSize!: number;

	dataSource = new MatTableDataSource<Dungeon>([]);
	dungeonsSelected: { [key: string]: boolean } = {};
	draftAhead: { [key: string]: { isAhead: boolean; version: number } } = {};
	noLive: string[] = [];
	isAllSelected: boolean = false;
	owners!: Owner[];

	subscriptions: Subscription;

	constructor(
		private dialog: MatDialog,
		private accountStore: AccountStore,
		private toastService: ToastService,
		private dungeonService: DungeonService,
		private metagameLinkService: MetagamelinkService
	) {
		this.subscriptions = new Subscription();
	}

	ngOnInit() {
		const accountSub = this.accountStore.listAccounts().subscribe((res) => {
			if (res == null) {
				return;
			}
			const systemOwner: Owner = {
				displayName: "SYSTEM",
				id: "system/system",
			};
			const devOwner: Owner = { displayName: "DEV", id: "dev/dev" };
			const arcadeOwner: Owner = {
				displayName: "ARCADE",
				id: "arcade/arcade",
			};
			const placementOwner: Owner = {
				displayName: "PLACEMENT",
				id: "placement/placement",
			};
			const accountOwners: Owner[] = (res as IAccount[])
				.sort((a, b) => a.displayName.localeCompare(b.displayName))
				.map((acc) => ({
					id: `accounts/${acc.uid}`,
					displayName: acc.displayName,
				}));
			this.owners = [
				systemOwner,
				devOwner,
				arcadeOwner,
				placementOwner,
				...accountOwners,
			];
		});

		this.subscriptions.add(accountSub);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.dungeons || changes.oppositeDungeons) {
			this.dataSource = new MatTableDataSource(this.dungeons);
			this.noLive = [];

			this.isAllSelected = false;

			this.dungeons.forEach((dungeon) => {
				this.dungeonsSelected[dungeon.uid] = false;
				const oppositeDungeon = this.oppositeDungeons.find(
					(l) => l.uid === dungeon.uid
				);
				if (!oppositeDungeon) this.noLive.push(dungeon.uid);
				this.draftAhead[dungeon.uid] = {
					isAhead: oppositeDungeon
						? !(oppositeDungeon.version === dungeon.version)
						: true,
					version: oppositeDungeon ? oppositeDungeon.version : -1,
				};
			});

			if (this.paginator) {
				this.setPaginator();
			}
		}

		if (changes.isDraft) {
			this.defaultPageSize = this.isDraft
				? +(sessionStorage.getItem("draft-dungeonsPageSize") ?? 10)
				: +(sessionStorage.getItem("live-dungeonsPageSize") ?? 10);
		}
	}

	ngAfterViewInit(): void {
		this.setPaginator();
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	createImage(id: string) {
		if (!id) {
			return `https://storage.googleapis.com/${environment.BUCKETS.CONTENT_BUCKET}/images/image_default.jpg`;
		}
		return `https://storage.googleapis.com/${environment.BUCKETS.CONTENT_BUCKET}/images/${id}.jpg`;
	}

	//#region GLOBAL ACTIONS

	openContentValidation(dungeon: Dungeon) {
		this.dialog.open(ContentValidationDialog, {
			width: "650px",
			data: dungeon as any,
		});
	}

	openAddDialog() {
		const dialogRef = this.dialog.open(AddDungeonDialog, {
			disableClose: true,
			data: {
				name: "",
				imageId: "",
				age: "2+",
				lessons: [],
				recommendation: {
					type: "Classic",
				},
				owner: "",
				version: 1,
				releasePhase: "Published",
			} as CreateDungeon,
		});

		dialogRef.afterClosed().subscribe((result: Dungeon) => {
			if (!result) {
				return;
			}

			this.toastService.success(
				`Dungeon ${result.uid} created successfully`
			);
			this.dungeonUpdate.emit(true);
		});
	}

	selectAll(event: MatCheckboxChange) {
		this.isAllSelected = event.checked;
		for (let dungeonId in this.dungeonsSelected) {
			this.dungeonsSelected[dungeonId] = event.checked;
		}
	}

	deleteDungeon() {
		const selectedIds = this.getSelected();
		if (selectedIds.length === 0) {
			this.toastService.error(
				`You must select at least a single lesson to delete!`
			);
			return;
		}

		if (selectedIds.length > 5) {
			this.toastService.error(`You can only delete 5 lessons at a time!`);
			return;
		}

		const dialogRef = this.dialog.open(DeleteConfirmationDialog, {
			width: "350px",
		});

		dialogRef.afterClosed().subscribe((result: boolean) => {
			if (!result) {
				return;
			}

			const dungeonDeleteRequests: Observable<boolean | void>[] = [];
			selectedIds.forEach((id: string) => {
				const request = this.dungeonService.removeDungeon(id).pipe(
					catchError((err) => {
						this.toastService.error(
							`Failed to delete dungeon with id ${id}`
						);
						return of(false);
					})
				);

				dungeonDeleteRequests.push(request);
			});

			forkJoin([...dungeonDeleteRequests]).subscribe({
				next: (res) => {
					if (!res.includes(false)) {
						this.toastService.success(
							`Deleted dungeons successfully`
						);
						this.dungeonUpdate.emit(true);
					}
				},
			});
		});
	}

	publishDungeons(release: boolean) {
		const selectedIds = this.getSelected();
		if (selectedIds.length === 0) {
			this.toastService.error(`You must select at least a single dungeon to publish/un-publish!`);
			return;
		}

		const requests: Observable<boolean>[] = [];

		selectedIds.forEach(dungeonId => {
			const request = this.dungeonService.publishDungeon(dungeonId, release)
				.pipe(
					catchError(error => {
						this.toastService.error(`Failed to publish dungeon ${dungeonId}`)
						console.log(`Failed to publish dungeonId ${dungeonId}`, error);
						return of(false);
					})
				);
			requests.push(request);
		});

		forkJoin(requests).subscribe(
			{
				next: (res) => {
					if (!res.includes(false)) {
						this.toastService.success('Dungeons updated successfully!')
						this.dungeonUpdate.emit(true);
					}
				}
			}
		)
	}

	pushToLive() {
		const selectedIds = this.getSelected();

		if (selectedIds.length === 0) {
			this.toastService.error(
				`You must select at least one lesson to push!`
			);
			return;
		}

		for (let id of selectedIds) {
			const selected = this.dungeons.find(dungeon => dungeon.uid === id);
			if (selected && !selected.isContentValid) {
				this.toastService.error(`Dungeon ${selected.name} has invalid content, please fix it or remove it from selected before pushing to live!`);
				return;
			}
		}

		this.dungeonService.pushDungeonsToLive(selectedIds).subscribe({
			next: () => {
				this.toastService.success(`Dungeons pushed to live correctly.`);
				this.dungeonUpdate.emit(true);
			},
			error: () => {
				this.toastService.error(`Failed to push dungeons live!`);
			},
		});
	}

	//#endregion

	//#region DUNGEON ACTIONS

	select(event: MatCheckboxChange, checkedId: string) {
		this.isAllSelected = false;
		this.dungeonsSelected[checkedId] = event.checked;
	}

	editDungeon(uid: string, dungeon: Dungeon) {
		const dialogRef = this.dialog.open(AddDungeonDialog, {
			disableClose: true,
			data: {
				uid,
				name: dungeon.name,
				imageId: dungeon.imageId,
				age: dungeon.age,
				lessons: dungeon.lessons,
				recommendation: dungeon.recommendation,
				owner: dungeon.owner,
				version: dungeon.version,
			} as Dungeon,
		});

		dialogRef.afterClosed().subscribe((result: Dungeon) => {
			if (!result) {
				return;
			}

			this.toastService.success(
				`Dungeon ${result.uid} updated successfully`
			);
			this.dungeonUpdate.emit(true);
		});
	}

	getOwner(ownerId: string) {
		let owner = this.owners.find((obj) => obj.id === ownerId);
		return owner ? owner.displayName : ownerId;
	}

	testDungeon(uid: string) {
		this.metagameLinkService.testDungeon(uid, this.isDraft);
	}

	//#endregion

	private setPaginator() {
		this.dataSource.paginator = this.paginator;
		this.paginator.pageIndex = this.isDraft
			? +(sessionStorage.getItem("draft-dungeonPageIndex") ?? 0)
			: +(sessionStorage.getItem("live-dungeonPageIndex") ?? 0);
	}

	paginatorChanged(event: any) {
		const mode = this.isDraft ? "draft" : "live";
		sessionStorage.setItem(`${mode}-dungeonsPageSize`, event.pageIndex);
		sessionStorage.setItem(`${mode}-dungeonsPageSize`, event.pageSize);
	}

	private getSelected() {
		const selectedIds = [];

		for (let id in this.dungeonsSelected) {
			const isFiltered = this.dataSource.filteredData.find(
				(lesson) => lesson.uid === id
			);
			if (this.dungeonsSelected[id] && isFiltered) {
				selectedIds.push(id);
			}
		}

		return selectedIds;
	}
}
